<template>
    <div class="game-box">
        <transition :name="nameAnimation" mode="out-in">
            <router-view
            :langContent="langContent" 
            @addTextFoundFooter="addTextFoundFooter" 
            @numberDifferences="numberDifferences"
            @blockQuest="blockQuest"
            @numberQuestGame="numberQuestGame">
            </router-view >
        </transition>
    </div>
</template>
<script>

    export default {
        props: {
            langContent: String
        },
        data(){
            return{
                showQuestButton: false,
                nameAnimation: '',
                removeScrollButton: true,
            }
        },
        methods:{
            numberQuestGame(numberQuest){
                this.$emit("numberQuestApp",numberQuest);
            },
            addTextFoundFooter(showTextFound){
                this.$emit('addTextFoundFooter', showTextFound)
            },
            numberDifferences(numberDifferences){
            this.$emit('numberDifferences', numberDifferences);
                
            },
            blockQuest(blockQuestButton){
                this.showQuestButton = blockQuestButton
                this.$emit('addQuestButtonFooter',this.showQuestButton)
            }
        },
        mounted(){
            if(localStorage.idGame){
                let routes = this.$router.options.routes
                let pathFinishedGame = routes[1].children[localStorage.idGame].path
                this.$router.push({ path: `/${this.langContent}/game/${pathFinishedGame}` }).catch(()=>{})
            } 
            else {
                let routes = this.$router.options.routes
                let pathFinishedGame = routes[1].children[0].path
                this.$router.push({ path: `/${this.langContent}/game/${pathFinishedGame}` }).catch(()=>{})
            }
            
        },
        created(){
            this.showQuestButton =  true
            this.$emit('addQuestButtonFooter',this.showQuestButton)
            setTimeout(()=>{
                this.nameAnimation = 'slideRight-fade'
            },500)
            this.removeScrollButton = false
            this.$emit('removeButtonScrollFooter',this.removeScrollButton)
        },

        destroyed() {
            this.showQuestButton =  false
            this.$emit('addQuestButtonFooter',this.showQuestButton)
            this.removeScrollButton = true
            setTimeout(()=>{
                this.$emit('removeButtonScrollFooter',this.removeScrollButton)
            },500)
        },
    }
</script>
